import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { services } from '../../constants';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

// تخصيص Popper لضمان ظهور القائمة للأسفل وفوق المحتوى الأساسي
const StyledPopper = styled('div')(({ theme }) => ({
    zIndex: 1300, // يضمن ظهور القائمة فوق المحتوى الأساسي
}));

const DropdownWithSearch = () => {
    let lng = i18next.language;
    const navigate = useNavigate();
    const options = services?.map((res) => ({
        value: res?.id,
        label: res?.name
    }));
    const [selectedArea, setSelectedArea] = useState(null);

    const handleChange = (event, newValue) => {
        if (newValue) {
            setSelectedArea(newValue.value);
            navigate(`/service/${newValue.value}`);
        }
    };

    return (
        <Autocomplete
            value={selectedArea ? options.find(option => option.value === selectedArea) : null}
            onChange={handleChange}
            options={options}
            getOptionLabel={(option) => option.label}
            PopperComponent={(props) => (
                <StyledPopper {...props} />
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={selectedArea ? options.find(option => option.value === selectedArea)?.label : (lng === 'ar' ? 'الأقسام' : 'category')}
                    variant="outlined"
                    fullWidth
                    sx={{
                        width: { md: '100%', xs: '100%' },
                        borderRadius: '16px'
                    }}
                />
            )}
            disablePortal={false} // استخدام البورتال لعرض القائمة بشكل صحيح
            ListboxProps={{
                style: {
                    maxHeight: '200px', // تحديد أقصى ارتفاع للقائمة
                    overflowY: 'auto',
                },
            }}
            openOnFocus={true} // فتح القائمة عند التركيز على الحقل
            noOptionsText={null} // إخفاء رسالة "No options found"
            sx={{
                width: { md: '100%', xs: '100%' },
                borderRadius: '16px'
            }}
        />
    );
};

export default DropdownWithSearch;
