import { Box, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import logo from './../../assets/logo.png'
import Pages from './Pages'
import Content from './Content'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
const TopFooter = () => {
    const { t } = useTranslation()
    const location = useLocation();
    let path = location.pathname.split('/')[1]
    const isBooking = path === "booking"
    return (
        <>
            <Grid container spacing={{ md: 4, xs: 1 }} >
                <Grid item md={6} xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: { md: 'initial', xs: 'center' } }} >
                        <CardMedia
                            component={'img'}
                            alt={'logo'}
                            src={logo}
                            sx={{ width: { md: '25%', xs: '35%' }, mt: 1 }}
                        />
                        <Typography sx={{ color: '#FFF', my: 2 }} >Revamp شركة مصرية متخصصة في التسويق الرقمى وتقنية المعلومات وجميع الخدمات المساندة للتجارة الإلكترونية.
                            نحن نسعى دائمًا لتحقيق أهداف عملائنا من خلال استراتيجيات متقدمة وفريق عمل محترف ونهدف  لفتح آفاق جديدة.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: { md: 1, xs: 0 }, direction: 'ltr' }} >
                        <Typography variant={'h5'} color={'#F0AF3C'} sx={{ direction: 'ltr' }} >
                            المقر في مصر
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1, direction: 'ltr' }} >
                            المنوفية - برج شلبي
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            <bdi> +201000814474</bdi>
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            <a href="mailto:info@revampbrands.com" style={{ textDecoration: 'none', color: '#FFF' }}>
                                info@revampbrands.com
                            </a>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: { md: 1, xs: 0 } }} >
                        <Typography variant={'h5'} color={'#F0AF3C'} >
                            المقر في السعودية
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            جدة
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            <bdi>  +966547376142</bdi>
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            <a href="mailto:ksa@revampbrands.com" style={{ textDecoration: 'none', color: '#FFF' }}>
                                ksa@revampbrands.com
                            </a>
                        </Typography>
                    </Box>
                </Grid>

            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { md: 'row', xs: 'column' }, gap: { md: 0, xs: 1 }, my: 0.5, pt: 2 }} >
                <Pages />
                <Typography sx={{ textAlign: 'center', color: '#fff', fontSize: '1rem' }}>
                    {t("copeRight")}
                </Typography>
                {!isBooking && <Content />}
            </Box>
        </>
    )
}

export default TopFooter