import React from 'react'
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import Card from './Card'
import ListItem from './ListItem'
import { Basic, Premium } from '../../constants'
import { useTheme } from '@emotion/react'

const BritishCompany = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Container maxWidth={'lg'}>
                <Typography variant='h5' my={3} textAlign={'center'} sx={{ fontWeight: 'bold' }} >تأسيس شركة بريطانية</Typography>
                <Box sx={{ mb: 3 }} >
                    <Grid container spacing={isMobile ? 2 : 4} >
                        <Grid item md={6} xs={12} >
                            <Card
                                type={`أساسي`}
                                price={`125`}
                                title={`دفعة لمرة واحدة إذا كنت تعمل بميزانية منخفضة`}>
                                <ListItem ListPackages={Basic} />
                            </Card>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card
                                type={`احترافي`}
                                price={`297`}
                                title={`دفعة لمرة واحدة`}
                            >
                                <ListItem ListPackages={Premium} />
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default BritishCompany