import home from './../assets/iconNav/Icon material-home.png'
import about from './../assets/iconNav/question.png'
import service from './../assets/iconNav/repairing-service.png'
import howWork from './../assets/iconNav/Icon material-group-work.png'
import book from './../assets/iconNav/Icon awesome-save.png'
import startNow from './../assets/iconNav/gains (1).png'
export const Pages = [
    {
        path: `/`,
        name: `الرئيسية`,
        name_en: `Home`,
        img: home
    },
    {
        path: `about`,
        name: `من نحن`,
        name_en: `About`,
        img: about
    },
    {
        path: `services`,
        name: `الخدمات`,
        name_en: `Our Services`,
        img: service
    },
    {
        path: `contact`,
        name: `كيفية العمل`,
        name_en: `Projects`,
        img: howWork
    },
    {
        path: `booking`,
        name: `تواصل معنا`,
        name_en: `Contact Us`,
        img: book
    },
    {
        path: `start-now`,
        name: `ابدأ الربح الآن`,
        name_en: `Our Blogs`,
        img: startNow
    },
]