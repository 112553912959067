import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box, CardMedia, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import './style.css'
import { Pages } from "../../constants/pages";

export default function NavDrawer({ setDrawer, drawer }) {
    const location = useLocation();

    // Close the drawer when the location changes
    React.useEffect(() => {
        setDrawer(false);
    }, [location, setDrawer]);
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="right"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    className="drw"
                    sx={{
                        '.MuiDrawer-paper': {
                            color: '#fff!important',
                            backgroundColor: '#000000e0 !important',
                            height: '75% !important ',
                            width: '75%',
                            borderBottomLeftRadius: '16px',
                            borderBottomRightRadius: '16px',
                            overflowY: 'auto'
                        }
                    }}
                >
                    <Box
                        sx={{
                            gap: 2,
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                        }}>
                        {Pages?.map((page, i) => (
                            <Link
                                key={i}
                                to={page.path}
                                style={{
                                    textDecoration: "none",
                                    fontSize: 18,
                                    textTransform: 'capitalize',
                                    fontWeight: 'bold'
                                }}
                                className="link"
                                onClick={() => setDrawer(false)}>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} className={'parentNav'} >
                                    <Box sx={{ padding: 1, borderRadius: '50%' }} className={'cov'} >
                                        <CardMedia
                                            alt='test'
                                            component={'img'}
                                            src={page.img}
                                            sx={{ height: '16px', width: '16px' }}
                                        />
                                    </Box>
                                    <Typography>{page.name}</Typography>
                                </Box>
                                
                            </Link>
                        ))}
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
