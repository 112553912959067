import { Box, InputLabel, Button, Select, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './ContactUS.css';
import { useTranslation } from 'react-i18next'
import { useAddSlotMutation, useGetSlotQuery } from '../../state/apiSlot';
import { format } from 'date-fns';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
const FormBooking = ({ children, isDisable }) => {
    // let limitDate = new Date(); // تاريخ اليوم الحالي
    // limitDate.setDate(limitDate.getDate() + 7);
    // console.log("🚀 ~ FormBooking ~ limitDate:", limitDate)

    const NewSlotSchema = Yup.object().shape({
        client_name: Yup.string().required('client_name is required'),
        client_phone: Yup.string().required('client_phone is required'),
        slot_id: Yup.string().required('slot_id is required'),
    });

    const { register, handleSubmit, reset } = useForm({

        resolver: yupResolver(NewSlotSchema),
    });

    // const {
    //     reset,
    //     watch,
    //     control,
    //     setValue,
    //     handleSubmit,
    //     formState: { isSubmitting },
    // } = methods;

    // const values = watch();


    const [startDate, setStartDate] = useState(new Date());
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const [timeSlot, setTimeSlot] = useState([]);
    const [Time, setTime] = React.useState('');
    const { data } = useGetSlotQuery({ day: formattedStartDate });

    const isDateAvailable = data?.data?.some(slot => slot.day === formattedStartDate);
    useEffect(() => {
        if (data) {
            if (!isDateAvailable) {
                enqueueSnackbar('تاريخ غير متاح', { variant: 'error' })
            } else {
                setTimeSlot(data?.data);
            }
        }
    }, [data, isDateAvailable]);
    const handleChange = (event) => {
        setTime(event.target.value);
    };

    const [phoneValid, setPhoneValid] = useState(true);

    const isPhoneValid = (phone) => {
        const phoneRegex = /^[0-9]{9,11}$/;
        return phoneRegex.test(phone);
    };

    const handlePhoneChange = (event) => {
        const phoneValue = event.target.value;
        setPhoneValid(isPhoneValid(phoneValue));
    };
    const { t } = useTranslation()
    const [addSlot, { isLoading }] = useAddSlotMutation()
    const onSubmit = async (formData) => {
        try {
            if (!phoneValid) {
                // إذا كان الرقم غير صالح، عرض رسالة الخطأ
                enqueueSnackbar("رقم غير صالح", { variant: 'error' });
            } else {
                // إذا كان الرقم صالح، قم بإرسال البيانات
                await addSlot(formData).unwrap();
                enqueueSnackbar("تمت عملية الحجز بنجاح");
                reset();
            }
        } catch (error) {
            enqueueSnackbar(error, { variant: 'error' });
            console.error(error);
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };
    return (
        <>
            <Box sx={{ width: '100%', height: { md: '43rem', xs: '100%' }, backgroundColor: '#F7F7F7', p: 2, borderRadius: '16px', margin: 'auto' }} >
                {children}

                <Box component={'form'} onSubmit={handleSubmit(onSubmit)}
                    p={2}
                    sx={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Box sx={{ width: { md: '100%', xs: '100%' }, m: 'auto', my: 1 }}>
                        <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                            {t("booking.name")}  <span style={{ color: '#F00000' }} >*</span>
                        </InputLabel>
                        <input
                            readOnly={isDisable === 'b' ? true : false}
                            name="client_name"
                            {...register('client_name')}
                            style={{ backgroundColor: '#FFF' }}
                            required
                            id="name"
                            variant="filled"
                            size="medium"
                            className='input'
                        />
                    </Box>
                    {/* ... Your existing code ... */}
                    <Box sx={{ width: { md: '100%', xs: '100%' }, m: 'auto', my: 1 }}>
                        <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }}>
                            {t("booking.phone")} <span style={{ color: '#F00000' }}>*</span>
                        </InputLabel>
                        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }} >
                            <input
                                readOnly={isDisable === 'b' ? true : false}
                                required
                                style={{ backgroundColor: '#FFF', borderColor: phoneValid ? '' : '#F00000' }}
                                id="phone"
                                variant="filled"
                                size="large"
                                name="client_phone"
                                {...register('client_phone')}
                                className='input'
                                onChange={handlePhoneChange}
                            />
                        </Box>
                        {!phoneValid && <span style={{ color: '#F00000' }}>Invalid phone number</span>}
                    </Box>
                    <Box sx={{ width: { md: '100%', xs: '100%' }, m: 'auto', my: 1 }}>
                        <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                            {t("booking.date")}  <span style={{ color: '#F00000' }} >*</span>
                        </InputLabel>
                        <DatePicker
                            readOnly={isDisable === 'b' ? true : false}
                            required
                            className='input'
                            selected={startDate}
                            minDate={new Date()}
                            filterDate={date => {
                                // إلغاء تحديد أيام الجمعة
                                return date.getDay() !== 5; // يوم الجمعة هو اليوم رقم 5
                            }}
                            onChange={(date) => setStartDate(date)} />
                    </Box>
                    <Box sx={{ width: { md: '100%', xs: '100%' }, m: 'auto', my: 1 }}>
                        <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                            {t("booking.time")}  <span style={{ color: '#F00000' }} >*</span>
                        </InputLabel>
                        <Select
                            readOnly={isDisable === 'b' ? true : false}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Time}
                            name="slot_id"
                            {...register('slot_id')}
                            sx={{ width: '100%', backgroundColor: '#FFF' }}
                            onChange={handleChange}>
                            {
                                isDateAvailable ? timeSlot?.map((time) => <MenuItem key={time?.id} value={time?.id}>{time?.title}</MenuItem>)
                                    : <MenuItem>{'لا يتوفر حجوزات في التاريخ الذي قمت بختياره'}</MenuItem>
                            }
                        </Select>
                    </Box>
                    <Box py={2} width={'100%'} >
                        <Button
                            disabled={isDisable === 'b' ? true : false}
                            type="submit"
                            sx={{
                                backgroundColor: '#FABB25', ':hover': {
                                    backgroundColor: '#FABB25'
                                },
                                color: "#000",
                                fontWeight: 'bold',
                                width: '100px',
                                textAlign: 'center',
                                my: 1
                            }}> {isLoading ? 'load' : t("booking.send")}  </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

// One time slot every 30 minutes.
// const timeSlots = Array.from(new Array(24 * 2)).map(
//     (_, index) =>
//         `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'
//         }`,
// );
export default FormBooking
